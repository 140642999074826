import React from "react"
import rehypeReact from "rehype-react"
//import { SkipNavLink } from "../components/skip-nav"
import { graphql } from "gatsby"
//import { Layout } from "../components/layout"
import { GatsbyImage } from "gatsby-plugin-image"
import Button from "../components/button"
import Cta from "../components/cta"
import { Seo } from "../components/seo"
import Navbar from "../components/navbar"
import styles from "../styles/style"
import FooterNew from "../components/footer-new"

const Article = ({ data }) => {
  const renderAst = new rehypeReact({
    createElement: React.createElement,
    components: { button: Button, cta: Cta },
  }).Compiler

  const { htmlAst } = data.markdownRemark
  const { title, tag, image, author } = data.markdownRemark.frontmatter

  //const { html } = data.markdownRemark
  //const { title, tag, image } = data.markdownRemark.frontmatter
  return (
    <div className="w-full overflow-clip">
      <Seo />
      {/* <SkipNavLink /> */}
      <div
        className={`bg-[#000000] sticky top-0 z-30 ${styles.paddingX} ${styles.flexCenter}`}
      >
        <div className={`${styles.boxWidth}`}>
          <Navbar />
        </div>
      </div>

      <div className="relative w-full">
        <GatsbyImage
          image={image.childImageSharp.gatsbyImageData}
          alt={title}
          style={{ height: "550px" }}
        />

        <div
          className={`absolute bottom-0 left-0 right-0 px-4 py-2 bg-[#000000] opacity-80 ${styles.paddingX} ${styles.flexCenter}`}
        >
          <div className={`${styles.boxWidth}`}>
            <h3 className="text-xl text-gradient font-bold">
              H&S Pepper Articles
            </h3>
            <p className="mt-2 text-sm text-gray-300">Written by - {author}</p>
          </div>
        </div>

        {/* 
            <div className="absolute bottom-0 left-0 right-0 px-4 py-2 bg-gray-800 opacity-70">
              <h3 className="text-xl text-white font-bold">
                Security Staffing Solutions - Articles
              </h3>
              <p className="mt-2 text-sm text-gray-300">Written by - {author}</p>
            </div>
           */}
      </div>

      <div className={`bg-[#FFFFFF] ${styles.paddingX} ${styles.flexCenter}`}>
        <div className={`${styles.boxWidth}`}>
          <section className="py-10">
            <span className="bg-black-gradient text-secondary py-1 px-2 font-semibold ">
              {tag}
            </span>
            <br />
            <h2 className="font-semibold md:text-3xl text-xl py-4 capitalize text-gradient">
              {title}
            </h2>

            <div>{renderAst(htmlAst)}</div>

            <p className="pt-8 text-orange-grad">
              Published in the {tag} category
            </p>
          </section>
        </div>
      </div>

      <div className={`bg-[#000000] ${styles.paddingX} ${styles.flexCenter}`}>
        <div className={`${styles.boxWidth}`}>
          <FooterNew />
        </div>
      </div>
    </div>
  )
}

export default Article

export const query = graphql`
  query ArticleQuery($slug: String) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      htmlAst
      frontmatter {
        title
        tag
        author
        image {
          childImageSharp {
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              aspectRatio: 1.99
            )
          }
        }
      }
    }
  }
`
