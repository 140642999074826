import React from "react"
import styles from "../styles/style"
//import Button from "./button"

const CTA = () => (
  <div className={`bg-primary ${styles.paddingX} ${styles.flexCenter}`}>
    <div className={`${styles.boxWidth}`}>
      <section
        className={`${styles.flexCenter} ${styles.marginY} ${styles.padding} sm:flex-row flex-col bg-black-gradient-3 rounded-[20px] box-shadow`}
      >
        <div className="flex-1 flex flex-col">
          <h2 className={`${styles.heading3} ss:leading-[80.8px]`}>
            <span className="text-gradient">Call us today!</span>
          </h2>

          <p
            className={`${styles.paragraph3} max-w-[800px] font-simibold  mt-5`}
          >
            If you are a customer looking for information on our products, or
            you want to become a partner or a stockiest of H&S Pepper Co, then
            make sure you contact us today!
          </p>
        </div>

        <div
          className={`${styles.flexCenter} sm:ml-10 ml-0 sm:mt-0 mt-10 mb-5`}
        >
          {/* <Button label="Get a quote today" /> */}
          <div className="block">
            <button
              className="py-4 px-9 w-full font-semibold border text-white hover:text-[#01bf71]  transition ease-in-out duration-200 rounded-xl  "
              type="button"
            >
              Book a call today
            </button>
          </div>
        </div>
      </section>
    </div>
  </div>
)

export default CTA
